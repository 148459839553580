/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import '@wiris/mathtype-tinymce5';
import { BeatLoader } from 'react-spinners';
import {
  IconBookOpenArea,
  IconBookStore,
  IconSchool,
  IconUploadImage,
  IconDocument,
} from '../../styles/icons';
import {
  getItemsContent,
  postCreateItem,
  putItem,
  uploadFiles,
} from '../../services/api';
import {
  Container,
  StepPanel,
  StepInfo,
  StepNumber,
  ContentStepInfo,
  ContentStep,
  Content,
  Card,
  ContentLeft,
  ContentRight,
  InputTitle,
  Bottom,
  ButtonNext,
  ButtonPrev,
  SelectArea,
  WarpForm,
  ContentButton,
  Second,
  Third,
  Fourth,
  Section,
} from './styles';
import CheckboxRadio from '../../components/forms/CheckboxRadio';
import Render, { IContent } from '../../components/template/Render';
import InputMany from '../../components/forms/InputMany';
import StudioEditor from '../../components/template/Editor';
import Upload from '../../components/forms/Upload';
import {
  getLevelOfLearning,
  IGetKnowledge,
  getKnowledge,
} from '../../services/api';
import { saveManageUnitsFromEditorLog } from '../../services/logs';
import { generateId } from '../../utils/generateId';
import { removePaste } from '../../utils/removePasteTinymce';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import ModalEditItem from '../../components/template/ModalEditItem';
import { PrimaryButton } from '../../components/template/TemplateManager/styles';
import ReactSelect from '../../components/forms/ReactSelect';

interface IParams {
  id: string;
}

interface IRender {
  title: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  knowlegdeLevel: Array<string>;
  knowlegdeArea1: string;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  bibliographicReference: Array<string>;
  content: IContent[];
}

interface IItem {
  id: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  bibliographicReference: Array<string>;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  title: string;
  levelOfLearning: Array<string>;
  knowledgeUniversity: {
    id: string;
  };
  knowledges: {
    id: string;
    title: string;
  }[];
  topics: string;
  status: string;
  item: {
    id: string;
  };
}

const Studio: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const routeParames: IParams = useParams();

  const history = useHistory();
  const contentLeft = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTopic, setCurrentTopic] = useState(0);

  const [idItem, setItemId] = useState('');
  const [levelOfLearning, setLevelOfLearning] = useState([]);
  const [knowledges, setKnowledges] = useState<IGetKnowledge[]>([]);
  const [knowledgeTypes, setKnowledgeTypes] = useState<Array<string>>([
    'university',
  ]);
  const [itemInitalState, setItemInitalState] = useState<IItem>({} as IItem);
  const [title, setTitle] = useState('');
  const [authors, setAuthors] = useState(['']);
  const [mediaUrl, setMediaUrl] = useState('');
  const [questions, setQuestions] = useState('');
  const [knowlegdeLevel, setKnowlegdeLevel] = useState(['']);
  const [knowlegdeArea1, setKnowlegdeArea1] = useState('');
  const [knowlegdeArea2, setKnowlegdeArea2] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [synthesis, setSynthesis] = useState('');
  const [learningObjectives, setLearningObjectives] = useState(['']);
  const [bibliographicReference, setBibliographicReference] = useState(['']);
  const [content, setContent] = useState<IContent[]>([
    {
      title: 'Tópico 01',
      sections: [{ content: '', type: 'txt' }],
    },
  ]);

  const [render, setRender] = useState<IRender>({
    title: '',
    authors: [''],
    mediaUrl: '',
    questions: '',
    knowlegdeLevel: [''],
    knowlegdeArea1: '',
    introduction: '',
    learningObjectives: [''],
    synthesis: '',
    bibliographicReference: [''],
    content,
  });

  let itemUpdate: IItem = {
    id: '',
    authors: [],
    mediaUrl: '',
    questions: '',
    bibliographicReference: [],
    introduction: '',
    learningObjectives: [],
    synthesis: '',
    title: '',
    levelOfLearning: [],
    knowledgeUniversity: {
      id: '',
    },
    knowledges: [],
    topics: '',
    status: '',
    item: {
      id: '',
    },
  };

  const stringTopics = useMemo(() => {
    let str = '';
    const topics = itemInitalState.id
      ? (JSON.parse(itemInitalState.topics) as IContent[])
      : [];
    topics.forEach(topic => {
      str += `<h1>${topic.title}</h1>`;
      topic.sections.forEach(section => {
        if (section.type === 'txt') {
          str += `${section.content}`;
        } else {
          str += `<div <div class="resource" data-interaction="true" data-type="${section.type}" data-subtype="${section.subtype}" data-resource='${section.content}'>${section.label}</div>`;
        }
      });
    });

    return str;
  }, [itemInitalState]);

  async function updateValues() {
    const { data: res } = await getItemsContent(routeParames.id);
    const item: IItem = res.content;
    itemUpdate = item;
    setItemInitalState(itemUpdate);
    return itemUpdate;
  }

  const [loadingItem, setLoadingItem] = useState(false);
  const itemLoaded = useMemo(() => {
    if (routeParames.id) {
      return !loadingItem && render.title !== '';
    }
    return true;
  }, [loadingItem, routeParames.id, render]);

  const labelButton = useMemo(() => {
    setLoadingItem(true);
    return routeParames.id ? 'Salvar' : 'Cadastrar';
  }, [routeParames.id]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      const level = await getLevelOfLearning();
      setLevelOfLearning(level as []);

      const know = await getKnowledge();
      setKnowledges(know);

      if (!routeParames.id) {
        return;
      }

      try {
        const { data: res } = await getItemsContent(routeParames.id);
        const item: IItem = res.content;
        setItemId(item.item.id);
        setRender({
          title: item.title,
          authors: item.authors,
          mediaUrl: item.mediaUrl,
          questions: item.questions,
          knowlegdeLevel: item.levelOfLearning,
          knowlegdeArea1: '',
          introduction: item.introduction,
          learningObjectives: item.learningObjectives.filter(
            item => item !== '',
          ),
          bibliographicReference: item.bibliographicReference,
          synthesis: item.synthesis,
          content: JSON.parse(item.topics) as IContent[],
        });
        setLoadingItem(false);
        setItemInitalState(item);

        return;
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Algo de errado aconteceu',
          type: 'error',
        });
      }
    };
    load();
  }, [addToast, routeParames.id]);

  useEffect(() => {
    if (Object.keys(itemInitalState).length === 0) {
      return;
    }

    setTitle(itemInitalState.title);
    setAuthors(itemInitalState.authors);
    setMediaUrl(itemInitalState.mediaUrl);
    setQuestions(itemInitalState.questions);
    setLearningObjectives(itemInitalState.learningObjectives);
    setIntroduction(itemInitalState.introduction);
    setSynthesis(itemInitalState.synthesis);
    setBibliographicReference(itemInitalState.bibliographicReference);
    setKnowlegdeLevel(itemInitalState.levelOfLearning);
    setKnowlegdeArea1(
      knowledges.find(
        item =>
          itemInitalState.knowledges
            .map(item2 => item2.id)
            .includes(item.value) && item.type === 'university',
      )?.value || '',
    );

    setKnowlegdeArea2(
      knowledges.find(
        item =>
          itemInitalState.knowledges
            .map(item2 => item2.id)
            .includes(item.value) && item.type === 'basic',
      )?.value || '',
    );

    if (itemInitalState.topics) {
      const objectContent = JSON.parse(
        itemInitalState.topics.replace(/'/g, '&#39;'),
      ) as IContent[];

      setContent(objectContent);
    }
  }, [itemInitalState, knowledges]);

  useEffect(() => {
    setRender({
      title,
      authors,
      mediaUrl,
      questions,
      knowlegdeLevel,
      knowlegdeArea1,
      introduction,
      learningObjectives,
      synthesis,
      bibliographicReference,
      content,
    });
  }, [
    title,
    authors,
    mediaUrl,
    questions,
    learningObjectives,
    introduction,
    knowlegdeArea1,
    knowlegdeLevel,
    synthesis,
    content,
    bibliographicReference,
  ]);

  useEffect(() => {
    let findKnowledgeType = levelOfLearning
      .filter((item: any) => knowlegdeLevel.includes(item.id))
      .map((item: any) => item.type) as Array<string>;
    findKnowledgeType = Array.from(new Set(findKnowledgeType));
    if (routeParames.id) {
      setKnowledgeTypes(
        findKnowledgeType.length > 0 ? findKnowledgeType : [''],
      );
    } else {
      setKnowledgeTypes(
        findKnowledgeType.length > 0 ? findKnowledgeType : ['university'],
      );
    }
  }, [knowlegdeLevel, levelOfLearning, routeParames.id]);

  useEffect(() => {
    localStorage.removeItem('@aurea:editor:editorjson');
    localStorage.removeItem('@aurea:editor:editortxt');
    return () => {
      localStorage.removeItem('@aurea:editor:editorjson');
      localStorage.removeItem('@aurea:editor:editortxt');
    };
  }, []);

  const handleNextPage = useCallback(() => {
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
      contentLeft.current?.scrollTo(0, 0);
    }
  }, [currentPage]);

  const handlePrevPage = useCallback(() => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
      contentLeft.current?.scrollTo(0, 0);
    }
  }, [currentPage]);

  const handleSubmit = useCallback(event => {
    event.preventDefault();
  }, []);

  const handleAuthors = useCallback((authorsArray: Array<string>) => {
    setAuthors(authorsArray);
  }, []);

  const handleLearningObjectives = useCallback(
    (learningObjectives: Array<string>) => {
      setLearningObjectives(learningObjectives);
    },
    [],
  );

  const handleKnowlegdeLevel = useCallback(
    (KnowlegdeLevelArray: Array<string>) => {
      setKnowlegdeLevel(KnowlegdeLevelArray);
    },
    [],
  );

  const handleKnowlegde = useCallback((knowlegdeArea: string) => {
    setKnowlegdeArea1(knowlegdeArea);
  }, []);

  const handleKnowlegde2 = useCallback((knowlegdeArea: string) => {
    setKnowlegdeArea2(knowlegdeArea);
  }, []);

  const handleContent = useCallback((contentEditor: any) => {
    setContent(contentEditor);
  }, []);

  const handleCurrentTopic = useCallback((i: number) => {
    setCurrentTopic(i);
  }, []);

  const handleSetModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleCreateUpdate = useCallback(
    async (updateMessage: string) => {
      const knowlegde = [knowlegdeArea1, knowlegdeArea2];
      await postCreateItem({
        itemId: idItem,
        authors: authors.filter(item => item !== ''),
        mediaUrl: mediaUrl || undefined,
        questions: questions || undefined,
        bibliographicReference: bibliographicReference.filter(
          item => item !== '',
        ),
        introduction,
        knowledges: knowlegde.filter(item => item !== ''),
        learningObjectives: learningObjectives.filter(input => input !== ''),
        synthesis: synthesis.length !== 0 ? synthesis : undefined,
        title,
        levelOfLearning: knowlegdeLevel.filter(item => item !== ''),
        topics: JSON.stringify(content).replace(/'/g, '&#39;'),
        updateMessage,
      });

      localStorage.removeItem('@aurea:editor:editorjson');
      localStorage.removeItem('@aurea:editor:editortxt');
      history.goBack();

      addToast({
        title: 'Sucesso!',
        description: `Item ${
          routeParames.id ? `atualizado` : `cadastrado`
        } com sucesso!`,
        type: 'success',
      });
    },
    [
      authors,
      mediaUrl,
      questions,
      bibliographicReference,
      content,
      introduction,
      knowlegdeArea1,
      knowlegdeLevel,
      learningObjectives,
      synthesis,
      title,
      idItem,
      addToast,
      history,
      routeParames.id,
      knowlegdeArea2,
    ],
  );

  const handleSubmitFinal = useCallback(async () => {
    try {
      if (title === '') {
        addToast({
          title: 'Ops ...',
          description: 'Insira o título desse item',
          type: 'error',
        });
        return;
      }

      setLoading(true);
      const knowlegde = [knowlegdeArea1, knowlegdeArea2];

      if (itemInitalState.status === 'publish') {
        // Cria uma nova versão quando o usuário edita um item publicado
        // Chama modal para submeter
        handleSetModal();
        return;
      }
      if (routeParames.id) {
        if (!synthesis) {
          addToast({
            title: 'Ops ...',
            description: 'Insira a síntese desse item',
            type: 'error',
          });
          return;
        }

        // Edita item não publicado
        await putItem({
          id: itemInitalState.id,
          authors,
          mediaUrl: mediaUrl || undefined,
          questions: questions || undefined,
          bibliographicReference: bibliographicReference.filter(
            item => item !== '',
          ),
          introduction,
          knowledges: knowlegde.filter(item => item !== ''),
          learningObjectives: learningObjectives.filter(item => item !== ''),
          synthesis: synthesis?.length !== 0 ? synthesis : '',
          title,
          levelOfLearning: knowlegdeLevel.filter(item => item !== ''),
          topics: JSON.stringify(content).replace(/'/g, '&#39;'),
        });
        updateValues();
      } else {
        // Cria item
        await postCreateItem({
          authors: authors.filter(item => item !== ''),
          mediaUrl: mediaUrl || undefined,
          questions: questions || undefined,
          bibliographicReference: bibliographicReference.filter(
            item => item !== '',
          ),
          introduction,
          knowledges: knowlegde.filter(item => item !== ''),
          learningObjectives: learningObjectives.filter(item => item !== ''),
          synthesis: synthesis?.length !== 0 ? synthesis : undefined,
          title,
          levelOfLearning: knowlegdeLevel.filter(item => item !== ''),
          topics: JSON.stringify(content).replace(/'/g, '&#39;'),
        });
        history.goBack();
      }

      localStorage.removeItem('@aurea:editor:editorjson');
      localStorage.removeItem('@aurea:editor:editortxt');

      saveManageUnitsFromEditorLog({
        userId: user.id,
        action: routeParames.id ? `updated_unity` : `created_unity`,
        unityId: routeParames.id ? routeParames.id : null,
      });

      addToast({
        title: 'Sucesso!',
        description: `Item ${
          routeParames.id ? `atualizado` : `cadastrado`
        } com sucesso!`,
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      addToast({
        title: 'Ops ...',
        description:
          'Algo de inesperado aconteceu, por favor tente novamente mais tarde',
        type: 'error',
      });
    } finally {
      setLoading(true);
    }
  }, [
    title,
    knowlegdeArea1,
    knowlegdeArea2,
    itemInitalState.status,
    itemInitalState.id,
    routeParames.id,
    history,
    addToast,
    handleSetModal,
    authors,
    mediaUrl,
    questions,
    bibliographicReference,
    introduction,
    learningObjectives,
    synthesis,
    knowlegdeLevel,
    content,
  ]);

  const [loadUpload, setLoadUpload] = useState(false);
  const [loadUploadStorageQuestion, setLoadUploadStorageQuestion] =
    useState(false);

  const handleUploadStorageQuestion = useCallback(
    async (files: File[]) => {
      setLoadUploadStorageQuestion(true);
      try {
        const formData = new FormData();
        formData.append('questions', files[0]);
        const response = await uploadFiles({
          formData,
          provider_id: user.provider?.id || '',
          type: 'questions',
        });
        setQuestions(response?.data[0].url);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
      } finally {
        setLoadUploadStorageQuestion(false);
      }
    },
    [addToast, user.provider],
  );

  const handleUpload = useCallback(
    async (files: File[]) => {
      setLoadUpload(true);

      try {
        const formData = new FormData();
        formData.append('images', files[0]);
        const response = await uploadFiles({
          formData,
          provider_id: user.provider?.id || '',
          type: 'images',
        });
        setMediaUrl(response?.data[0].url);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
      } finally {
        setLoadUpload(false);
      }
    },
    [addToast, user.provider],
  );

  const renderFirstStep = useCallback(() => {
    return (
      <Card className="slideUp">
        <InputTitle>
          <IconBookStore />
          <input
            type="text"
            placeholder="Título da unidade..."
            name="title"
            defaultValue={title}
            onChange={e => setTitle(e.target.value)}
          />
        </InputTitle>
        <Section>
          <IconUploadImage className="iconUrl" fontSize={40} />
          <input
            type="text"
            placeholder="Insira um link de uma imagem para o serviço..."
            name="mediaUrl"
            defaultValue={mediaUrl}
            onChange={e => setMediaUrl(e.target.value)}
          />
          <label>ou</label>
          <Upload
            onUpload={handleUpload}
            formts={[
              { extension: '.jpg', types: 'image/jpg' },
              { extension: '.jpeg', types: 'image/jpeg' },
              { extension: '.png', types: 'image/png' },
              { extension: '.gif', types: 'image/gif' },
            ]}
            loading={loadUpload}
          />
        </Section>

        <InputMany
          icon={IconSchool}
          placeholder="AUTOR"
          wrap
          defaultValue={authors.map(autor => {
            return { id: generateId(), value: autor };
          })}
          handleInputs={handleAuthors}
        />
        <Section>
          <IconDocument
            className="iconUrl"
            style={{ width: '25px', marginRight: '10px', paddingTop: '31px' }}
          />
          <input
            type="text"
            placeholder="Faça o upload de um arquivo para o banco de questões..."
            name="questionStorageUrl"
            defaultValue={questions}
            onChange={e => setQuestions(e.target.value)}
            style={{ margin: '0px 0px 20px 0px' }}
          />
          <Upload
            onUpload={handleUploadStorageQuestion}
            formts={[
              { extension: '.doc', types: 'application/msword' },
              {
                extension: '.docx',
                types:
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              },
              { extension: '.pdf', types: 'application/pdf' },
            ]}
            loading={loadUploadStorageQuestion}
            style={{ margin: '0px 0px 20px 0px' }}
          />
        </Section>
        <CheckboxRadio
          itens={levelOfLearning}
          defaultValue={knowlegdeLevel}
          handleCheckbox={handleKnowlegdeLevel}
        />
        <SelectArea>
          <IconBookOpenArea />
          {knowledgeTypes.includes('university') && (
            <ReactSelect
              name="area1"
              options={[
                { label: 'Áreas de conhecimento', value: '' },
                ...knowledges.filter(know => know.type === 'university'),
              ]}
              placeholder="Áreas de conhecimento avançado"
              handleValue={handleKnowlegde}
              defaultValue={knowlegdeArea1}
            />
          )}

          {knowledgeTypes.includes('basic') && (
            <ReactSelect
              name="area2"
              options={[
                { label: 'Áreas de conhecimento', value: '' },
                ...knowledges.filter(know => know.type === 'basic'),
              ]}
              placeholder="Áreas de conhecimento basico"
              handleValue={handleKnowlegde2}
              defaultValue={knowlegdeArea2}
            />
          )}
        </SelectArea>
      </Card>
    );
  }, [
    title,
    mediaUrl,
    handleUpload,
    loadUpload,
    handleUploadStorageQuestion,
    loadUploadStorageQuestion,
    questions,
    authors,
    handleAuthors,
    levelOfLearning,
    knowlegdeLevel,
    handleKnowlegdeLevel,
    knowledgeTypes,
    knowledges,
    handleKnowlegde,
    knowlegdeArea1,
    handleKnowlegde2,
    knowlegdeArea2,
  ]);

  const renderSecondStep = useCallback(() => {
    return (
      <Second className="slideUp second">
        <Card className="tinymce">
          <h1>Introdução</h1>
          <Editor
            initialValue={introduction}
            onEditorChange={e => setIntroduction(e)}
            init={{
              width: '100%',
              height: '300',
              menubar: true,
              statusbar: false,
              content_style: `
              h1 {
                font-size: 2em;
                margin: 0.67em 0;
                color: #2c3038;
                border-bottom: 1px dashed #e0dede;
              }
              .resource {
                width: 80% !important;
                margin: 14px auto;
                text-align: center;
                padding: 18px;
                background: #F6C866;
                border-radius: 5px;
                color: #3D3939;
                font-weight: bold;
                border: 1px solid #F6C866;
                position: relative;
              }
              .latex {
                font-family: monospace;
                background-color: #ccc;
              }
              .resource::after{
                content: '';
                top: 8px;
                left: 2%;
                position: absolute;
                border-radius: 5px;
                font-weight: bold;
                border: 1px dashed #F1F2F6;
                background: transparent;
                width: 96%;
                height: 40px;
              }
            `,
              block_formats:
                'Parágrafo=p; Tópico=h1; Subtópico=h2; Título=h3; Subtítulo=h4; Latex=latex',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'charmap codesample media emoticons autoresize tiny_mce_wiris mathtype-editor',
                'paste',
              ],
              external_plugins: {
                tiny_mce_wiris:
                  'https://www.wiris.net/demo/plugins/tiny_mce/plugin.min.js',
              },
              ...removePaste,
              toolbar: `formatselect | interaction | bold italic | forecolor backcolor |
                 alignleft aligncenter alignright alignjustify | bullist numlist |
                link | image image-format | add-mathtype `,
              contextmenu: 'link image imagetools table spellchecker',
              images_upload_handler(blobInfo, success) {
                async function LoadImageServer() {
                  const formData = new FormData();

                  formData.append('images', blobInfo.blob());

                  const response = await uploadFiles({
                    formData,
                    provider_id: user.provider?.id as string,
                    type: 'images',
                  });
                  success(response?.data[0].url);
                }
                LoadImageServer();
              },
            }}
          />
        </Card>
        <Card>
          <h1>Objetivos de Aprendizagem</h1>
          <InputMany
            placeholder="NOVO OBJETIVO DE APRENDIZAGEM"
            wrap={false}
            defaultValue={learningObjectives.map(level => {
              return { id: generateId(), value: level };
            })}
            handleInputs={handleLearningObjectives}
          />
        </Card>
      </Second>
    );
  }, [handleLearningObjectives, introduction, learningObjectives, user]);

  const renderThirdStep = useCallback(() => {
    return (
      <Third className="slideUp third">
        <StudioEditor
          handleContent={handleContent}
          handleCurrentTopic={handleCurrentTopic}
          defaultValue={stringTopics}
        />
      </Third>
    );
  }, [handleContent, handleCurrentTopic, stringTopics]);

  const renderFourthStep = useCallback(() => {
    return (
      <Fourth className="slideUp fourth">
        <Card className="tinymce">
          <h1>Síntese</h1>
          <Editor
            initialValue={synthesis}
            onEditorChange={e => setSynthesis(e)}
            init={{
              width: '100%',
              height: '300',
              menubar: true,
              statusbar: false,
              content_style: `
          h1 {
            font-size: 2em;
            margin: 0.67em 0;
            color: #2c3038;
            border-bottom: 1px dashed #e0dede;
          }
          .resource {
            width: 80% !important;
            margin: 14px auto;
            text-align: center;
            padding: 18px;
            background: #F6C866;
            border-radius: 5px;
            color: #3D3939;
            font-weight: bold;
            border: 1px solid #F6C866;
            position: relative;
          }
          .latex {
            font-family: monospace;
            background-color: #ccc;
          }
          .resource::after{
            content: '';
            top: 8px;
            left: 2%;
            position: absolute;
            border-radius: 5px;
            font-weight: bold;
            border: 1px dashed #F1F2F6;
            background: transparent;
            width: 96%;
            height: 40px;
          }
        `,
              block_formats:
                'Parágrafo=p; Tópico=h1; Subtópico=h2; Título=h3; Subtítulo=h4; Latex=latex',
              toolbar:
                'formatselect | interaction | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image | add-mathtype',
              plugins: ['paste tiny_mce_wiris mathtype-editor'],
              external_plugins: {
                tiny_mce_wiris:
                  'https://www.wiris.net/demo/plugins/tiny_mce/plugin.min.js',
              },
              ...removePaste,
            }}
          />
        </Card>
        <Card style={{ minHeight: '300px', height: '300px' }}>
          <h1 style={{ marginBottom: '10px' }}>Referencial Teórico</h1>
          <p style={{ marginBottom: '20px' }}>
            Para inserir mais de um referencial, use * para separar um do outro{' '}
          </p>
          <Editor
            initialValue={bibliographicReference.join('*')}
            onEditorChange={e => setBibliographicReference(e.split('*'))}
            init={{
              width: '100%',
              height: '300',
              menubar: false,
              statusbar: false,
              toolbar:
                'formatselect | interaction | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image',
              plugins: ['paste'],
              entity_encoding: 'raw',
              paste_auto_cleanup_on_paste: true,
              paste_remove_styles: true,
              paste_remove_styles_if_webkit: true,
              paste_strip_class_attributes: true,
              paste_word_valid_elements:
                'p,b,strong,i,em,h1,h2,ul,li,ol,table,tr,td,th,sub,sup',
            }}
          />
        </Card>
      </Fourth>
    );
  }, [bibliographicReference, synthesis]);

  const steps = useMemo(() => {
    return [
      renderFirstStep(),
      renderSecondStep(),
      renderThirdStep(),
      renderFourthStep(),
    ];
  }, [renderFirstStep, renderSecondStep, renderThirdStep, renderFourthStep]);

  const pageResult = useMemo(() => {
    if (currentPage === 0) return 0;
    if (currentPage === 1) return 0;
    if (currentPage === 2) {
      if (currentTopic === 0) {
        return 1;
      }
    }
    if (currentPage === 3) return render?.content?.length + 1;
    return currentTopic;
  }, [render, currentPage, currentTopic]);
  return (
    <>
      <ModalEditItem
        isOpen={isOpen}
        setIsOpen={handleSetModal}
        handleInitialState={handleCreateUpdate}
      />
      <Container className="blur slideDown">
        <StepPanel>
          <ContentStep>
            <StepInfo className={`${currentPage >= 0 ? `active` : ``}`}>
              <StepNumber className="step-number">01</StepNumber>
              <ContentStepInfo>
                <h1>Etapa 1</h1>
                <p>Nova unidade</p>
              </ContentStepInfo>
            </StepInfo>
            <StepInfo className={`${currentPage >= 1 ? `active` : ``}`}>
              <StepNumber className="step-number">02</StepNumber>
              <ContentStepInfo>
                <h1>Etapa 2</h1>
                <p>Introdução</p>
              </ContentStepInfo>
            </StepInfo>
            <StepInfo className={`${currentPage >= 2 ? `active` : ``}`}>
              <StepNumber className="step-number">03</StepNumber>
              <ContentStepInfo>
                <h1>Etapa 3</h1>
                <p>Conteúdo</p>
              </ContentStepInfo>
            </StepInfo>
            <StepInfo className={`${currentPage >= 3 ? `active` : ``}`}>
              <StepNumber className="step-number">04</StepNumber>
              <ContentStepInfo>
                <h1>Etapa 4</h1>
                <p>Síntese</p>
              </ContentStepInfo>
            </StepInfo>
          </ContentStep>
          <ContentButton>
            <PrimaryButton onClick={() => handleSubmitFinal()}>
              SALVAR
            </PrimaryButton>
            <PrimaryButton
              className="outline-white"
              onClick={() => history.goBack()}
            >
              Sair
            </PrimaryButton>
          </ContentButton>
        </StepPanel>
        <Content>
          {itemLoaded ? (
            <>
              <ContentLeft ref={contentLeft}>
                <form method="POST" onSubmit={handleSubmit}>
                  <WarpForm>{steps[currentPage]}</WarpForm>
                  <Bottom>
                    {currentPage !== 0 && (
                      <ButtonPrev onClick={() => handlePrevPage()}>
                        Voltar
                      </ButtonPrev>
                    )}
                    {currentPage === 3 ? (
                      <ButtonNext onClick={() => handleSubmitFinal()}>
                        {labelButton}
                      </ButtonNext>
                    ) : (
                      <ButtonNext onClick={() => handleNextPage()}>
                        Avançar
                      </ButtonNext>
                    )}
                  </Bottom>
                </form>
              </ContentLeft>
              <ContentRight>
                <Render render={render} currentTopic={pageResult} editor />
              </ContentRight>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                height: '100vh',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BeatLoader size={12} />
            </div>
          )}
        </Content>
      </Container>
    </>
  );
};

export default Studio;
